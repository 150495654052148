import React from "react";
import FooterOne from "../components/FooterOne";
import NavbarTwo from '../components/NavbarTwo';
import WorkProcessFour from "../components/WorkProcessFour";
import SliderDetail from "../components/Sliders/SliderDetail";
import AboutSection from "../components/AboutSection/AboutSection";
import WhatWeDo from "../components/WhatWeDo/WhatWeDo";
import NewsletterSection from "../components/NewsletterSection/NewsletterSection";
import LatestNews from "../components/LatestNews/LatestNews";

const HomeOne = () => {
  return (
    <>
      <NavbarTwo />
      <SliderDetail />
      <AboutSection />
      <WhatWeDo />
      <NewsletterSection />
      <WorkProcessFour />
      <LatestNews />
      <FooterOne />
    </>
  );
};

export default HomeOne;
