import { BrowserRouter, Routes, Route } from 'react-router-dom';
import About from './pages/About';
import Contact from './pages/Contact';
import HomeOne from './pages/HomeOne';
import Service from './pages/Service';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect } from 'react';
import ScrollToTop from 'react-scroll-to-top';
import RevenueManagementCycle from './components/Services/Rcm/RevenueManagementCycle';
import VirtualMedicalAssistants from './components/Services/VirtualMedicalAssistants/VirtualMedicalAssistants';
import WorkersCompensation from './components/Services/WorkersComp/WorkersCompensation';
import PersonalInjury from './components/Services/PersonalInjury/PersonalInjury';
import NotFound from './pages/NotFound';

function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: 'ease',
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<HomeOne />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/service" element={<Service />} />
        <Route exact path="/revenue-cycle-management" element={<RevenueManagementCycle />} />
        <Route exact path="/virtual-medical-assistants" element={<VirtualMedicalAssistants />} />
        <Route exact path="/workers-compensation" element={<WorkersCompensation />} />
        <Route exact path="/personal-injury" element={<PersonalInjury />} />
        <Route exact path="*" element={<NotFound />} />

      </Routes>
      <ScrollToTop smooth color="#246BFD" />
    </BrowserRouter>
  );
}

export default App;
