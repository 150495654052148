import React from 'react';
import './NotFoundPage.css';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
    return (
        <div className="not-found">
            <div className="not-found-content">
                <h1 className="not-found-heading">404</h1>
                <p className="not-found-text">Oops! The page you are looking for doesn't exist.</p>
                <Link to="/" className="not-found-link">Go Back Home</Link>
            </div>
        </div>
    );
}

export default NotFoundPage;
