import React from 'react'
import { MdOutlineDateRange } from "react-icons/md";
import { Link } from 'react-router-dom';
import './style.css'
import rcmInsightNewsOne from './img/rcmInsightNewsOne.jpg'
import rcmInsightNewsTwo from './img/rcmInsightNewsTwo.jpg'
import rcmInsightNewsThree from './img/rcmInsightNewsThree.jpg'

function LatestNews() {
    return (
        <>
            <div className="container mt-3 mb-3">
                <h2 className="title mb-4 text-center">Latest Insights</h2>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-new-list mb-4">
                            <div className="thumb">
                                <img
                                    className="border-radius-5 img-fluid "
                                    src={rcmInsightNewsOne}
                                    alt="rcmInsightNewsOne"
                                />
                            </div>
                            <div className="single-news-details">
                                <ul className="blog-meta ps-0">
                                    <li></li>
                                    <li></li>
                                </ul>
                                <h5 className="mb-3">
                                    <Link to="/">
                                         
                                    </Link>
                                </h5>
                                <p>
                                    
                                </p>
                                {/* <ul className="blog-meta ps-0">
                                    <li>
                                        <MdOutlineDateRange /> August 2, 2024
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-new-list mb-4">
                            <div className="thumb">
                                <img
                                    className="border-radius-5 img-fluid "
                                    src={rcmInsightNewsTwo}
                                    alt="rcmInsightNewsTwo"
                                />
                            </div>
                            <div className="single-news-details">
                                <ul className="blog-meta ps-0">
                                    <li></li>
                                    <li> </li>
                                </ul>
                                <h5 className="mb-3">
                                    <Link to="/">
                                         
                                    </Link>
                                </h5>
                                <p>
                                
                                </p>
                                {/* <ul className="blog-meta ps-0">
                                    <li>
                                        <MdOutlineDateRange /> August 2, 2024
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-new-list">
                            <div className="thumb">
                                <img
                                    className="border-radius-5 img-fluid"
                                    src={rcmInsightNewsThree}
                                    alt="rcmInsightNewsThree"
                                />
                            </div>
                            <div className="single-news-details">
                                <ul className="blog-meta ps-0">
                                    <li></li>
                                    <li> </li>
                                </ul>
                                <h5 className="mb-3">
                                    <Link to="/">
                                         
                                    </Link>
                                </h5>
                                <p>
                                    
                                </p>
                                {/* <ul className="blog-meta ps-0">
                                    <li>
                                        <MdOutlineDateRange /> August 2, 2024
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LatestNews